/** @jsx jsx */
import {jsx, css} from "@emotion/react";
import { useEffect, useState } from "react";
import Page from "../layouts/page";

const hero = css`
font-size: 4em;
font-weight: normal;
`

const rapidIxSpan = css`
color: #44718E;
`

const tableStyles = css`
&, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 20px;
}
`

const centerStyles = css`
margin-top: 40px;
margin-bottom: 40px;
text-align: center;
display: flex;
justify-content: center;
`

const prettyCapacity = (capacity: number) => {
    if (capacity < 1000) {
        return `${capacity}M`;
    } else {
        return `${capacity / 1000}G`
    }
}

const Loading = () => {
    return <tr>
        <td colspan="5">Loading data...</td>
    </tr>
}

const Members = () => {
    const [members, setMembers] = useState([]);

    useEffect(() => {
        fetch(`https://portal.rapidix.net/api/v4/member-export/ixf/1.0`)
            .then(response => response.json())
            .then(data => {
                let newData = data.member_list.map(member => {member.member_since = new Date(member.member_since); return member;});

                newData.sort((a, b) => a.member_since < b.member_since)

                setMembers(newData)
            });
    }, []);

    return <Page>
        <h1 css={hero}><span css={rapidIxSpan}>RapidIX</span> Members</h1>

        <div css={centerStyles}>
            <table css={tableStyles}>
                <thead>
                <tr>
                    <th>ASN</th>
                    <th>Organisation</th>
                    <th>Addresses</th>
                    <th>Capacity</th>
                    <th>Peering Policy</th>
                </tr>
                </thead>
                <tbody>
                    {members.length == 0 ? <Loading/> : members.map((port) => <tr key={port.asnum}>
                        <td><strong>AS{port.asnum}</strong></td>
                        <td style={{textAlign: "left"}}>{port.url ? <a href={port.url}>{port.name}</a> : port.name}</td>
                        <td>{port.connection_list[0].vlan_list[0].ipv4.address}<br/>{port.connection_list[0].vlan_list[0].ipv6.address}</td>
                        <td>{prettyCapacity(port.connection_list[0].if_list[0].if_speed)}</td>
                        <td>{port.peering_policy}</td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    </Page>
}

export default Members
